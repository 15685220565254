<template>
  <transition-group name="fade" tag="div" class="message-container">
    <div v-for="(msg, index) in messages" :key="index" class="message-box" @mouseenter="stopTimer(index)" @mouseleave="startTimer(index)">
      <div class="message-header">
        <h3 class="message-title">来新消息了</h3>
        <h3 class="message-title">{{ msg.title }}</h3>
        <span class="close-icon" @click="closeMessageBox(index)">&times;</span>
      </div>
      <div class="message-content">
        <h4 class="message-sender">{{ msg.sender }}</h4>
        <template v-if="msg.type === 'txt'">
          <p class="message-text">{{ msg.content }}</p>
        </template>
        <template v-else-if="msg.type === 'img'">
          <viewer>
            <img :src="msg.content" alt="消息图片" class="message-image" @click="stopTimer(index)" />
          </viewer>
        </template>
      </div>
      <div class="message-buttons-wrapper">
        <div class="message-buttons">
          <button @click="viewMessage(index)" class="button button-view">查看</button>
          <button @click="closeMessageBox(index)" class="button button-confirm">确认</button>
        </div>
      </div>
    </div>
  </transition-group>
</template>

<script>
export default {
    data() {
        return {
            messages: [],
            timers: [],
        }
    },
    props: {
        gotoPath: {
            type: String,
            default: '/msg',
        },
    },
    methods: {

        // 判断是否支持显示
        showJudge(data) {
            if (!('Notification' in window)) {
                alert('抱歉，此浏览器不支持桌面通知！')
            }
            // granted: 用户允许该网站发送通知 default: 默认值，用户还未选择 denied: 用户拒绝该网站发送通知
            // Notification.permission === 'granted' 可用于检测用户通知权限
            Notification.requestPermission().then((result) => {
                if (result === 'denied') {
                    console.log('用户拒绝')
                    return
                } if (result === 'default') {
                    console.log('用户未授权')
                    return
                }
                this.sendMesgToDesk(data)
            })
        },
        // 显示消息通知
        sendMesgToDesk(data) {
            let notification = null
            let title = '收到新的消息'
            let str1 = data.content
            if (data.type == 'img') {
                str1 = '图片'
            }
            let options = {
                tag: data.title, // 多条消息时tag相同只显示一条通知，需要显示多条时tag一定要不同，（谷歌每次只能显示一条，火狐可以显示多条）
                body: str1, // 通知主体
                data: { // 可以放置任意数据，方便后续使用
                    content: data.content,
                    originUrl: 'https://back.bjca.xyz/manage/#/msg',
                },
                requireInteraction: false, // 不自动关闭通知 默认值为false，通知会在三四秒之后自动关闭，（谷歌有用，火狐依然会自动关闭）
                image: require('../assets/imgs/logo.jpg'), // 通知上方可显示需要展示的大图
                icon: require('../assets/imgs/user.jpg'), // 通知图标 默认是浏览器图标
            }
            notification = new Notification(title, options)
            // 事件处理
            notification.onclick = ({ currentTarget: { data } }) => {
                // notification.close() 单个通知关闭
                window.focus()
                // 默认进入系统之前打开的页面，也可以这里自定义进入的页面
                window.location.href = data.originUrl
            }
            notification.onshow = () => {
                console.log('通知显示了')
            }
            notification.onclose = () => {
                console.log('通知被关闭了')
            }
            notification.onerror = () => {
                console.log('遇到错误')
            }
        },
        viewMessage(index) {
            this.$router.push(this.gotoPath)
            this.closeMessageBox(index)
        },
        showMessage(message) {
            this.showJudge()
            console.log(message)
            this.messages.push(message)
            this.sendMesgToDesk(message)
            const timer = setTimeout(() => {
                this.closeMessageBox(this.messages.length - 1)
            }, 3000)
            this.timers.push(timer)
        },
        closeMessageBox(index) {
            clearTimeout(this.timers[index])
            this.messages.splice(index, 1)
            this.timers.splice(index, 1)
        },
        stopTimer(index) {
            clearTimeout(this.timers[index])
        },
        startTimer(index) {
            const timer = setTimeout(() => {
                this.closeMessageBox(index)
            }, 3000)
            this.timers[index] = timer
        },
    },
}
</script>

<style scoped>
.message-container {
  position: fixed;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

.message-box {
  width: 320px;
  padding: 20px;
  border-radius: 8px;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 99999;
  animation-duration: 0.3s;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s ease-out;
}

.fade-enter {
  opacity: 0;
  transform: translateX(100%) scale(0.8);
}

.fade-leave-to {
  opacity: 0;
  transform: translateX(100%) scale(0.8);
}

.message-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.message-title {
  font-size: 16px;
  color: #333;
  font-weight: bold;
}

.close-icon {
  font-size: 20px;
  color: #999;
  cursor: pointer;
}

.message-content {
  height: 150px;
  overflow-y: auto;
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.message-sender {
  font-size: 14px;
  font-weight: bold;
  color: #007bff;
  margin-bottom: 5px;
  text-decoration: underline;
}

.message-text {
  font-size: 14px;
  color: #333;
  line-height: 1.5;
  margin-top: 10px;
}

.message-image {
  max-width: 100%;
  max-height: 300px;
  object-fit: contain;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.message-buttons-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.message-buttons {
  display: flex;
  gap: 10px;
}

.button {
  padding: 6px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s ease-out;
}

.button:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.button-view {
  background-color: #007bff;
  color: #fff;
  border: none;
}

.button-view:hover {
  background-color: #0069d9;
}

.button-confirm {
  background-color: #28a745;
  color: #fff;
  border: none;
}

.button-confirm:hover {
  background-color: #218838;
}
</style>